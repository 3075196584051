import { Box, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
<>
<Box sx={{p:2}}>
    <Typography sx={{color:'', fontSize:'13px', textAlign:'center'}}>Proudly Powered By <a href="reisty.com" style={{color:'#BC172F'}}>Reisty.com</a> </Typography>
    <Typography sx={{color:'', fontSize:'13px', textAlign:'center', mt:1}}>&#169; 2024 </Typography>
</Box>
</>
  )
}

export default Footer