import logo from "./logo.svg";
import "./App.css";
import { Routes } from "./Routes";
import { ThemeProvider, createTheme } from "@mui/material";
import ScrollToTop from "./ScrollToTop";
import { Analytics } from "@vercel/analytics/react";
import { HelmetProvider } from "react-helmet-async";

const theme = createTheme({
  palette: {
    primary: {
      main: "#BC172F",
    },
  },
  typography: {
    fontFamily: "outfit",
    fontWeight: 300,
    fontSize: "14px",
    button: {
      textTransform: "initial",
      fontWeight: 400,
    },
    caption: {
      fontSize: "11px",
    },
  },
});
function App() {
    const helmetContext = {};
  return (
    <>
      <ThemeProvider theme={theme}>
        <HelmetProvider  context={helmetContext} >
        <Routes />
        </HelmetProvider>
        <Analytics />
        <ScrollToTop />
      </ThemeProvider>
    </>
  );
}

export default App;
