import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Button } from "@mui/material";

const containerStyle = {
  width: '100%',
  height: '400px'
};

const MapContainer = ({ variant, restaurantAddress }) => {
  const originAddress = restaurantAddress;
  const destinationAddress = restaurantAddress;
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(null);
  const [originCoords, setOriginCoords] = useState(null);
  const [destinationCoords, setDestinationCoords] = useState(null);

  useEffect(() => {
    const fetchCoordinates = async (address, setCoords) => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: address,
              key: 'AIzaSyBWlx74ECq1RzRGOhO64Uq0sQp8-i8UjfU' // Replace with your API key
            }
          }
        );
        const { lat, lng } = response.data.results[0].geometry.location;
        setCoords({ lat, lng });
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    };

    fetchCoordinates(originAddress, setOriginCoords);
    fetchCoordinates(destinationAddress, setDestinationCoords);
  }, [destinationAddress, originAddress]);

  useEffect(() => {
    if (originCoords && destinationCoords) {
      setCenter(originCoords);
    }
  }, [originCoords, destinationCoords]);

  const getDirections = () => {
    if (!originCoords || !destinationCoords) {
      console.error("Coordinates not loaded yet");
      return;
    }

    const url = `https://www.google.com/maps/dir/?api=1&origin=${""}&destination=${destinationCoords.lat},${destinationCoords.lng}&travelmode=driving`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (center && window.google) {
      const mapInstance = new window.google.maps.Map(document.getElementById("map"), {
        center: center,
        zoom: 16,
      });
      new window.google.maps.Marker({
        position: center,
        map: mapInstance,
      });
      setMap(mapInstance);
    }
  }, [center]);

  if (!center) {
    return <div>Loading...</div>; // Show a loading indicator while fetching coordinates
  }

  return (
    <>
      {variant === "button" ? (
        <Button sx={{ p: 0, cursor: 'pointer' }} onClick={getDirections}>Get Directions</Button>
      ) : (
        <div id="map" style={containerStyle}></div>
      )}
    </>
  );
};

export default MapContainer;
