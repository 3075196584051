
import axios from "axios";


const apiKey = process.env.REACT_APP_API_CODE;

const baseUrl = process.env.REACT_APP_BASE_URL;
// console.log(apiEndpoint);
console.log(apiKey, baseUrl)
// Create an Axios instance for API requests
const apiClient = axios.create({
  baseURL: baseUrl,
  timeout: 120000,
});

// Add an interceptor to set authorization header with user token before requests
apiClient.interceptors.request.use(
  function (config) {
    // Retrieve user token from local storage
    const userToken = JSON.parse(window.localStorage.getItem("userInfo"));
    // console.log(token);
    // Set authorization header with bearer token
    config.headers.Authorization = `Bearer ${userToken?.access_token}`;
    config.headers.apiKey = apiKey;
    // config.headers["Access-Control-Allow-Origin"] = "*";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

const getRestaurantEvent = (id,eventName) =>{
    return apiClient.get(`restaurantevent/${id}/name/${eventName}`)
}
const getSingleTicket = (id) =>{
    return apiClient.get(`restaurantevent/ticket/${id}`)
}

const sendBooking = (ticketId, noOfGuest,firstName, lastName, emailAddress, phoneNumber,referralCode,amount, paymentMethod, payStackTransactionId ) =>{
  const bookingPayment={
    amount,
    paymentMethod,
    payStackTransactionId,
  }

  const payload = {
    ticketId, noOfGuest,firstName, lastName, emailAddress, phoneNumber, referralCode,bookingPayment
  }

  console.log(payload, "Payload")
  return apiClient.post('restaurantevent/ticket/book', payload)
}



export {
getRestaurantEvent,
getSingleTicket,
sendBooking

};
