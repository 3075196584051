import { Box, Typography, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import gifg from "../../assets/icon/7efs.gif";
import event from "../../assets/img/event.png";
import dateIcon from "../../assets/icon/date.svg";
import clock from "../../assets/icon/time.svg";
import guest from "../../assets/icon/guest.svg";
import location from "../../assets/icon/location.svg";
import { Link, useNavigate } from "react-router-dom";

import "add-to-calendar-button";

const Booked = () => {
  const history = useNavigate();
  const [data, setData] = useState("");
  const handleGoBackTwice = () => {
    // Go back twice in the history stack
    history(-2);
  };

  useEffect(() => {
    const bookingData = localStorage.getItem("Booked Data");
    if (bookingData) {
      setData(JSON.parse(bookingData));
    }
  }, []);

  function formatDate(inputDateStr) {
    // Create a new Date object from the input date string
    const inputDate = new Date(inputDateStr);

    // Extract year, month, and day from the input date
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth() + 1; // Month index starts from 0
    const day = inputDate.getDate();

    // Pad single-digit month and day with leading zeros if needed
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDay = String(day).padStart(2, "0");

    // Construct the output date string in the format "YYYY-MM-DD"
    const outputDateStr = `${year}-${formattedMonth}-${formattedDay}`;

    return outputDateStr;
  }
  const inputDateStr = data?.Date;
  const formattedDate = formatDate(inputDateStr);
  return (
    <>
    
      <Box sx={{ mt: 2, minHeight: "1100px", pb: 4 }}>
        <Box sx={{ bgcolor: "#fff", border: "1px solid #dadada" }}>
          <Box
            sx={{
              borderBottom: "1px solid #dadada",
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={gifg} width={150} />
            <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
              Reservation Booked
            </Typography>
            <Typography sx={{ fontSize: "13px", textAlign: "center" }}>
              Please check your inbox for a confirmation email and your ticket.
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: { md: "35%", xs: "100%" },
                border: "1px solid #dadada",
                p: 2,
                display: "flex",
                columnGap: 3,
                boxSizing: "border-box",
              }}
            >
              <img src={data?.RestaurantDetails?.ImageUrl} width={100} />
              <Box>
                <Typography sx={{ fontWeight: 500 }}>
                  {data?.RestaurantDetails?.RestaurantName} - {data?.EventName}
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  align="center"
                  sx={{ mt: 1 }}
                >
                  <img src={dateIcon} width={10} />
                  <Typography sx={{ fontSize: "10px" }}>
                    {data?.Date}
                  </Typography>
                </Stack>
                {/* <Stack
                  direction="row"
                  spacing={2}
                  align="center"
                  sx={{ mt: 0.5 }}
                >
                  <img src={location} width={15} />
                  <Typography sx={{ fontSize: "12px" }}>
                    TerraKulture, Nigeria
                  </Typography>
                </Stack> */}
                <Stack
                  direction="row"
                  spacing={2}
                  align="center"
                  sx={{ mt: 0.5 }}
                >
                  <img src={clock} width={14} />
                  <Typography sx={{ fontSize: "10px" }}>
                    {data?.Time}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  align="center"
                  sx={{ mt: 0.5 }}
                >
                  <img src={guest} width={14} />
                  <Typography sx={{ fontSize: "10px" }}>
                    {data?.NumberOfGuest}
                  </Typography>
                </Stack>
              </Box>
            </Box>

            <Box
              sx={{
                width: { md: "30%", xs: "100%" },
                border: "1px solid #dadada",
                p: 2,
                boxSizing: "border-box",
              }}
            >
              {/* <AddToCalendar   event={{
    startDate: formattedDate,
    endDate: formattedDate,
    summary: `${data?.EventName} Event`,
    description: 'Discuss project details',
    location: data?.RestaurantDetails?.
    Address
    ,
  }}/> */}
              {formattedDate && (
                <add-to-calendar-button
                  name={`${data?.EventName} Event`}
                  options="'Apple','Google', 'iCal','Outlook.com','Microsoft 365'"
                  location={data?.RestaurantDetails?.Address}
                  startDate={formattedDate}
                  endDate={formattedDate}
                  startTime={data.Time}
                  endTime={data?.Time}
                  timeZone="currentBrowser"
                ></add-to-calendar-button>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Box
            sx={{
              width: { md: "40%", xs: "100%" },
              boxSizing: "border-box",
              mt: { md: 0, xs: 2 },
            }}
          >
            <Box sx={{ border: "1px solid #dadada" }}>
              <Box sx={{ height: "0px" }}></Box>
              <Box sx={{ bgcolor: "#fff", p: 2 }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  {data?.RestaurantDetails?.RestaurantName}
                </Typography>
                <Typography sx={{ fontSize: "13px", mt: 2 }}>
                  <span style={{ textDecoration: "underline" }}>Address:</span>{" "}
                  {data?.RestaurantDetails?.Address}
                </Typography>
                <a href={data?.RestaurantDetails?.Website} target="_blank">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      mt: 1,
                      color: "#BC172F",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    {data?.RestaurantDetails?.Website}
                  </Typography>
                </a>
                <a href={`tel:${data?.RestaurantDetails?.PhoneNumber}`}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      mt: 2,
                      color: "#BC172F",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    {data?.RestaurantDetails?.PhoneNumber}
                  </Typography>
                </a>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box align="center" sx={{ mt: 6 }}>
          <Button
            onClick={handleGoBackTwice}
            size="large"
            variant="contained"
            sx={{ px: 8, py: 2, borderRadius: 0 }}
          >
            Back to home
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Booked;
