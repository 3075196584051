import { Box, Button, Typography } from '@mui/material'
import React from 'react'

const Ticket = ({type, event, price, onClick, ticketLeft}) => {
  return (
  <>
 {/* <div class="container" style={{marginBottom:'10px', }}>

  <div class="item">
    <div class="item-right" style={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
   <Typography sx={{ fontWeight:900, fontSize:'23px', }}>{type}</Typography>
   <Typography sx={{ fontWeight:500, fontSize:'16px', }}>Ticket</Typography>
     
      <span class="up-border"></span>
      <span class="down-border"></span>
    </div> 
    
    <div class="item-left">
    {event.length > 55 ? (
                          <Typography
                            sx={{
                              color: "#000",
                              fontSize: "12px",
                              lineHeight: "15px",
                              fontWeight: 400,mb:1
                            }}
                          >
                            {event.slice(0, 55)}...
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: 400,
                              color: "#000",
                              fontSize: "12px",
                              lineHeight: "15px",
                              mb:1
                            }}
                          >
                            {event}
                          </Typography>
                        )}
      <Typography sx={{fontWeight:700, fontSize:'20px'}}> {price} </Typography>
<Box align="right">

      <Button onClick={onClick} variant='contained' sx={{px:4, borderRadius:1, mt:2, fontSize:'12px', }}>Book Now</Button>
</Box>
    </div>
  </div> 
  
  
  

  
  
</div> */}

<Box sx={{border:'1px solid #D9D9D9', borderRadius:'12px', py:'24px', px:'16px', display:'flex', alignItems:'center', justifyContent:'space-between',  mb:2}}>
<Box>
  <Typography sx={{fontWeight:700, fontFamily:'optima'}}>{type}</Typography>
  <Typography className='two-line-ellipsis' sx={{color:'#717171', fontSize:'12px', fontWeight:300}}>{event}</Typography>
     {
      ticketLeft > 0 ? (
        <Button onClick={onClick} variant='contained' sx={{px:4, borderRadius:'8px', mt:2, fontSize:'12px', }}>Book Now</Button>
      ):(
        <Button disabled variant='contained' sx={{px:4, borderRadius:'8px', mt:2, fontSize:'12px', }}>Sold  Out</Button>
      )
     }
</Box>
    <Typography sx={{fontWeight:700, fontSize:'20px', fontFamily:'optima'}}>N{price.toLocaleString()} </Typography>

</Box>
  </>
  )
}

export default Ticket