import React, { useState, useEffect } from "react";
import { Box, Grid, MenuItem, Skeleton, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import { MdOutlineLocationOn } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import Ticket from "../../Component/common/Ticket";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRestaurantEvent } from "../../api";
import Booking from "./Booking";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moneyIcon from  '../../assets/icon/money.svg'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MapContainer from "../../Component/common/MapContainer";
import { IoCalendarSharp } from "react-icons/io5";
import { GoClockFill } from "react-icons/go";
import { ImLocation } from "react-icons/im";
import { FaMoneyCheck } from "react-icons/fa";
import { Helmet } from "react-helmet-async";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export const TicketPage = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const { name,id } = useParams();
    const [value, setValue] = React.useState(0);
    const [step, setStep] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate()


  const handleGetTickets = async () => {
    setLoading(true);
    await getRestaurantEvent(
      id,
      name
    )
      .then((res) => {
        console.log(res);
        setLoading(false);
        setData(res?.data?.result);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetTickets();
  }, []);


  return (
    <>


 {
  (step === 0) ? (
         <Box className="hide_scrollbar" sx={{ mt: 2, minHeight: "1000px",overflow:'scroll' , p:2}}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
         <Box>
              {loading ? (
                <Skeleton variant="square" sx={{ width: "30%" }} />
              ) : (
                <Typography sx={{ fontWeight: 700, fontSize: "24px" , fontFamily:'optima'}}>
                  {data?.EventName}
                </Typography>
              )}

              <Box sx={{ mt: 0 }}>
                {loading ? (
                  <Skeleton width="30%" variant="sqare" />
                ) : (
                  <Rating
                    defaultValue={data?.RestaurantRating}
                    precision={0.1}
                    sx={{ fontSize: "15px", color:'#DF8E14' }}
                    size="large"
                    readOnly
                  />
                )}

                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                >
                  {loading ? (
                    <Skeleton width="40%" variant="square" />
                  ) : (
                    <>
                 {/* <FaMoneyCheck />
                      <Typography sx={{fontFamily:'outfit', fontWeight:300, fontSize:'13px'}}>
                     {" "}
                        ₦{data?.RestaurantAverageCost.toLocaleString()}
                      </Typography> */}
                    </>
                  )}
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2, mt:2 }}
                >
                  {loading ? (
                    <Skeleton width="40%" variant="square" />
                  ) : (
                    <>
                    <IoCalendarSharp />
                      <Typography sx={{fontFamily:'outfit', fontWeight:300, fontSize:'13px'}}>
                      
                        { data?.EventDate && formatDate(data?.EventDate)}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2, mt:2 }}
                >
                  {loading ? (
                    <Skeleton width="40%" variant="square" />
                  ) : (
                    <>
                    <GoClockFill />
                      <Typography sx={{fontFamily:'outfit', fontWeight:300, fontSize:'13px'}}>
                      
                        {data?.EventTime}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1.5,
                    mt: 1,
                  }}
                >
                 <ImLocation />
                  <Typography sx={{fontWeight:300, fontSize:'13px'}}>{data?.EventAddress}</Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 6 }}>
                 <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs  variant="fullWidth" value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Bookings" {...a11yProps(0)} />
          <Tab label="Menu" {...a11yProps(1)} />
          <Tab label="Reviews" {...a11yProps(2)} />
          <Tab label="Details" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
   <Typography sx={{ fontWeight: 500, fontSize:'14px' }}>
                  Details
                </Typography>
                <Typography>
                  
                </Typography>
              <ReactQuill
      value={data?.EventDescription}
      readOnly={true}
      theme={null} // Disable theme
      modules={{ toolbar: false }}
      style={{fontFamily:'outfit'}}
      className="custom-quill-editor" // Apply custom CSS class
    />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
          <Typography sx={{}}>No Menu available</Typography>
{/*   
     <a href={`https://www.reisty.com/restaurant/test-kitchen`}>
   <Typography sx={{textDecoration:'underline'}}>Click here to view  link</Typography>
     </a> */}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <Typography sx={{}}>No Reviews available</Typography>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <Typography sx={{ fontWeight: 500, fontSize:'14px' }}>
                  Details
                </Typography>
              <ReactQuill
                          value={data?.EventDescription}
                          readOnly={true}
                          theme={null} // Disable theme
                          modules={{ toolbar: false }}
                        />
      </CustomTabPanel>
    </Box>
              
                {/* <Box
                  sx={{
                    background: "rgba(255, 217, 100, 0.20)",
                    p: 2,
                    mt: 3,
                  }}
                >
                  <Typography sx={{ fontWeight: 500, fontSize: "13px" }}>
                    Important Note
                  </Typography>
                  <Typography
                    sx={{ fontSize: "13px", mt: 2, lineHeight: "22px" }}
                  >
                    We offer a 15-minute grace period. If you anticipate
                    arriving more than 15 minutes past your reservation time,
                    kindly give us a call in advance.
                  </Typography>
                </Box> */}

                <Box sx={{ mt: 4 }}>
                  <Typography sx={{ fontWeight: 500, fontSize: "13px" }}>
                    Tickets
                  </Typography>
                  {/* <Typography
                    sx={{ fontSize: "13px", mt: 1, lineHeight: "22px" }}
                  >
                    "Discover a symphony of flavors on our exquisite menu."
                  </Typography> */}
                  <Box sx={{ mt: 2 }}>
                    {loading ? (
                      <>
                        <Skeleton variant="square" sx={{ height: "200px" }} />
                        <Skeleton
                          variant="square"
                          sx={{ height: "200px", my: 2 }}
                        />
                        <Skeleton variant="square" sx={{ height: "200px" }} />
                      </>
                    ) : (
                      <>
                        {data?.TicketDetails.map((item, index) => (
                          // <Link to={`/booking/${item?.
                          //   TicketId
                          //   }`}>

                          <Ticket
                            onClick={() =>{
                               setTicketData(item?.TicketId)
                             setStep(2)
                              }}
                            type={item?.TicketType}
                            price={item?.TicketCost}
                            event={item?.TicketDetails}
                            ticketLeft={item?.TicketLeft}
                          />

                          //  </Link>
                        ))}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
     
          <Grid item md={12} sm={12}  xs={12} sx={{ overflow: "hidden",  }}>
          
 
          <MapContainer variant={"map"} restaurantAddress={data?.EventAddress
}/>
     
   <Box
                sx={{
                  p: 3,
                  boxSizing: "border-box",
                  border: "1px solid #d7d7d7",
                }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                  Locate {data?.EventName}
                </Typography>

                <Typography sx={{ mt: 0.5, fontSize: "12px" }}>
                  {data?.EventAddress}
                </Typography>

                <Box sx={{ mt: 3 }}>
                  <a href={`tel:${data?.PhoneNumaber}`}>
                    <Typography color={"primary"}>
                      {data?.PhoneNumaber}
                    </Typography>
                  </a>
                  <Box sx={{ mt: 2 }}>
                    <MapContainer
                      variant="button"
                      restaurantAddress={data?.EventAddress}
                    />
                  </Box>
             
                  <Box sx={{ mt: 2 }}>
                    <a href={`mailto:${data?.EmailAddress}`}>
                      <Typography color="primary">
                        {data?.EmailAddress}
                      </Typography>
                    </a>
                  </Box>
                </Box>
              </Box>
  
          </Grid>
        </Grid>
      </Box>
  ):(
  <Booking event_id={ticketData} setStep={()=>setStep(0)} />
  )
 }
   

    </>
  );
};

function formatDate(dateString) {
  const date = new Date(dateString);

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}







