import React, { useState, useEffect } from "react";
import img1 from "../../assets/img/banner.png";
import { Box, Button, Typography } from "@mui/material";

const Banner = ({ data }) => {
  const images = [img1, img1, img1];
  // console.log(data);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [images.length, 6000]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <>
      <Box
        className="carousel"
        sx={{
          height: { md: "400px", xs: "200px" },
          background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('${data?.EventImageUrl}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat:'no-repeat',
          backdropFilter:'blur(20px)',

        }}
      >
     <Box
        className="carousel"
        sx={{
          height: { md: "400px", xs: "200px" },
          background: `url('${data?.EventImageUrl}')`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat:'no-repeat'
        }}
      />
      </Box>
    </>
  );
};

export default Banner;
