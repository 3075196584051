import React, { useEffect, useState } from "react";
import Navbar from "../Component/common/Navbar";
import {
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import Banner from "../Component/common/Banner";
import { MdOutlineLocationOn } from "react-icons/md";
import MapContainer from "../Component/common/MapContainer";
import { GiMoneyStack } from "react-icons/gi";
import Ticket from "../Component/common/Ticket";
import { Outlet, useParams } from "react-router-dom";
import Footer from "../Component/common/Footer";
import { getRestaurantEvent } from "../api";
import logo from "../assets/logo/logo.svg";
import { Helmet } from 'react-helmet-async';
import giffy from "../assets/icon/giphy.gif";
import Lottie from "react-lottie";
import anime from '../assets/anime/uKGMJQo3HR.json'

const Home = () => {

    const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { name, id } = useParams();

  console.log(name, id, "Sanuel");
  const handleGetTickets = async () => {
    setLoading(true);
    await getRestaurantEvent(id, name)
      .then((res) => {
        console.log(res);
        setLoading(false);
      if(res?.data?.status){
          setData(res?.data?.result);
      }else{
        setData(null)
      }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetTickets();
  }, []);
  return (
    <>



      { loading ? (
        <>
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <img src={giffy} width={140} />
          </Box>
        </>
      ) : (
        <>
          {
            (!data || data?.length === 0 ) ? (
              <>
<Box sx={{bgcolor:'#eee', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
        <Lottie options={defaultOptions} width={200} height={200} />
<Typography sx={{fontWeight:500, fontSize:'30px'}}>Event Not Found</Typography>

<a href="https://www.reisty.com/"><Typography color="primary" sx={{textDecoration:'underline', fontSize:'12px', mt:1}}>Explore available events</Typography></a>
</Box>

              </>
            ):(
              <>
<Box sx={{   backgroundColor: "rgba(188, 23, 47, 0.05)",}}>
          <Box sx={{      width: { xl: "30%", lg: "40%", md: "50%", sm: "65%", xs: "100%" }, margin: "0 auto", backgroundColor:'#fff' }}>
            <Box sx={{display:'grid', placeContent:'center', height:'60px', borderBottom:'5px solid #bc172f'}}>
              <img src={logo} alt='reisty_logo'/>
            </Box>
            <Banner data={data} />
            <Outlet />
          </Box>
          <Footer />
          </Box>
              </>
            )
          }
        </>
      )}
    </>
  );
};

export default Home;


