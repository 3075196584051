import { InputLabel, TextField } from "@mui/material";
import React from "react";

const CustomInput = ({
  label,
  placeholder,
  value,
  onChange,
  helperText,
  error,
}) => {
  return (
    <>
      <InputLabel sx={{ fontWeight: 500, fontSize: "13px", color: "#2A2A2A" }}>
        {label}
      </InputLabel>
      <TextField
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        helperText={helperText}
        error={error}
        margin="normal"
        InputProps={{
          style: {
            fontFamily: "outfit",
            fontWeight: 600,
            borderRadius: "0px",
            color: "#717171",
            padding: "0px 19px 0px 20px",
            fontSize: "13px",
            borderRadius:'8px'
          },
        }}
      />
    </>
  );
};

export default CustomInput;
