import { useRoutes } from "react-router-dom";
import Home from "../pages/Home";
import { TicketPage } from "../pages/sections/TicketPage";
import Booking from "../pages/sections/Booking";
import Booked from "../pages/sections/Booked";

export function Routes () {
    let element = useRoutes([
        {

element:<Home/>,
children:[
    {
        path:'/event/:name/:id',
        element:<TicketPage/>
    },
    {
        path:'/booking/:name/:id',
        element:<Booking/>
    },
    {
        path:'/booked',
        element:<Booked/>
    }
]
        }
    ])
    return element;
}